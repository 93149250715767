@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
  background-color: #EFEFEF;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
