.headline {
  font-size: 52px;
  margin: 0;
}

.card {
  background: #FFF;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,.3);
  border-radius: 12px;
  padding: 40px 20px;
}

.form {
  padding-top: 1%;
  padding-bottom: 1%;
}

.input {
  border: 1px solid #b7b7b7;
  color: rgba(0,0,0,0.9);
  padding: 20px 1%;
  font-size: 18px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.btn {
  cursor: pointer;
  border: 1px solid #81b44c;
  background: #81b44c;
  color: rgba(255,255,255,0.9);
  padding: 10px 1% 10px 1%;
  font-size: 22px;
  font-weight: bold;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 100%;
  transition: all 0.25s ease-out;
}

.btn:hover {
  background: #638A3A;
  text-decoration: none;
  color: #FFF;
  -webkit-box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
          box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
}

.btn:focus {
  background: #638A3A;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.block {
  width: 98%;
}

p {
  font-size: 20px;
}

.alert {
  width: 400px;
}

.truncate {
  display: block;
  width: 288px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.redirect {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 0 0;
}

@media (max-width: 767px) {
  .headline {
    font-size: 35px;
  }
  .card {
    padding: 30px 10px;
  }
  .btn {
    margin-top: 15px;
    padding: 15px 0;
  }
  .btn.block {
      width: 100%;
  }
}

@media (max-width: 420px) {
  .alert {
    width: 300px;
  }
  .truncate {
    width: 190px;
  }
}
